body {
    transition: unset;
}
.homepage-div-main {
    position: relative;
    height: 100%;
    background-color: rgb(var(--clr-background));

    .top-div .introducton {
        padding: 20px;
    }

    .top-div {
        border-radius: 0 0 20px 20px;
        background-size: cover;
        background-position: center;
        background-image: linear-gradient(140deg, rgba(var(--clr-background-lighter), 0.5), rgba(var(--clr-background-lighter))), url('../../../asset/img/pexels-rpnickson-2417862.jpg');
    }
    .top-div .introducton h2 {
        animation: paragraph .8s;
    }
    .top-div .introducton h1 {
        animation: paragraph .8s;
    }

    .top-div .introducton p {
        margin-top: 10px;
        max-width: 150px;
        animation: paragraph .8s;
    }

    .top-div .introducton .about-us {
        display: flex;
        gap: 20px;
        margin-top: 40px;

        nav ul {
            display: flex;
            align-items: center;
            margin-top: 10px;
            gap: 10px;
            list-style-type: none;
        }
    }

    .new-music {
        padding-left: 20px;
        transition: height 1.3s ease-in-out;
    }

    .bottom-div {
        margin-top: 4px;
        position: relative;
        width: 100%;
        min-height: 60%;
        background-color: rgb(var(--clr-background-lighter));
        border-radius: 20px 20px 0 0;
    }

    /* global classes */

    .hidden {
        opacity: 0;
    }
}

/* amination classes */
