/* root variables for specific component */

.au-div-main-audio-uploader {
  padding: 7.33px;
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  width: 100%;
  border-radius: 6px;
  background-color: rgba(var(--clr-background));
  overflow: hidden;
  align-items: center;
  position: absolute;
  z-index: 10;
  transition: opacity 0.6s ease-in;
}

.au-form-head {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 340px;
  padding: 35px 24px;

  h5 {
    text-decoration: dashed;
  }

  p {
  }
}

.au-div-heading-and-btn {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.au-div-heading-and-btn h3 {
  position: relative;
}

.au-div-upload-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 340px;
  padding-inline: 24px;
  gap: 12px;
  align-items: center;
}

.div-inputwrap {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  max-width: 295px;
}

.flex-columns {
  display: flex;
  margin-top: 50px;
  gap: 12px;

  .note {
    font-size: 14px;
    padding: 18px;
  }
}

.div-inputwrap select,
.div-inputwrap select option {
  height: 36px;
  background-color: rgba(var(--clr-font), 0.65);
  color: rgba(var(--clr-font), 0.8);
  border: none;
  border-radius: 4px;
}

.div-inputwrap select option {
  background-color: rgba(var(--clr-background), 1);
  color: rgba(var(--clr-font), 1);
}
.div-inputwrap select:focus {
  outline: none;
}

.div-inputwrap p {
  font-size: 14px;
  width: 100%;
  color: rgba(var(--clr-font), 0.6);
}

.div-inputwrap input {
  height: 35px;
  border: none;
  outline: none;
  border-bottom: 1px solid rgba(var(--clr-main), 0.5);
  width: 100%;
  background-color: rgba(var(--clr-font), 0.65);
  color: rgba(var(--clr-background), 1);
  padding-inline: 6px;
  border-radius: 6px;
}

.div-inputwrap input:focus {
  border: none;
  border-bottom: 1px solid rgba(var(--clr-main), 0.5);
}

.cancel {
  height: 28px;
  width: 28px;
}

.div-inputwrap input[type="file"] {
  height: unset;
  border: none;
  outline: none;
  border: 1px solid rgba(var(--clr-main), 0.5);
  width: 100%;
  color: rgba(var(--clr-main), 0.8);
  padding-inline: unset;
  padding: 6px;
}

.cover-input-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 295px;
  gap: 20px;

  .cover-input .div-inputwrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .cover-input .div-inputwrap p {
    width: 100%;
    text-align: center;
    position: absolute;
  }
}

.cover-input {
  width: 100%;
  height: 100px;
  background-size: cover;
  position: relative;
  margin-bottom: 24px;
  border: 1px solid rgba(var(--clr-font), 0.5);
  border-radius: 4px;

  input {
    padding: 40px 20px;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0;
  }
}

.audio-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 36px;
  border: 1px solid rgba(var(--clr-font), 0.5);
  border-radius: 4px;

  p {
    padding: 3px;
    position: absolute;
    margin-left: 10px;
  }

  .div-inputwrap {
    width: 100%;
    height: 100%;
    opacity: 0;
    gap: unset;

    p {
      display: none;
    }
  }

  .div-inputwrap input {
    width: 100%;
    height: 100%;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(100%);
  }
}
