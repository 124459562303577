  

 .audioplayer-div-main-song {
   padding: 3px;
   display: flex;
   height: 50px;
   width: 100%;
   border-radius: 6px;
   border: 1px solid rgba(var(--clr-font), 0.25);
   overflow: hidden;
   align-items: center;
   transition: opacity .4s ease-out;
    
    
    .ap-div-cover {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 100%;
      aspect-ratio: 1;
      border-radius: 6px;
      overflow: hidden;
      transition: filter .3s ease-out;
    }

    .ap-div-cover-admin {
      justify-content: space-evenly;
      width: 90px;
    }

    .ap-div-cover:hover {
      filter: contrast(.6)
    }
    
    .ap-div-cover .ap-img-cover {
      position: relative;
      filter: brightness(.5);
      object-fit: cover;
      transition: opacity .3s ease-in;
      height: 100%;
    }
    
    .ap-div-cover .ap-img-playpauseicon {
      position: absolute;
      width: 27.33px;
      filter: brightness(1.5);
      transition: transform .3s ease-out;
    }
    .ap-div-cover .ap-img-playpauseicon:hover {
      transform: scale(1.1);
    }
    
    .ap-div-info {
      display: flex;
      flex-direction: column;
      gap: 3px;
      margin-inline: 7px;
      height: 100%;
      justify-content: center;
      overflow: hidden;
    }

    .ap-div-info {
    .artist {
      font-size: 1.4rem;
    }

    .name {
      display: inline-block;
      font-size: 1.267rem;
      max-height: 12.67px;
      overflow: hidden;
    }
    }

    /* @keyframes slideRigtInfinite {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
    } */

    .ap-div-icons {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
    }

    .ap-div-icons .plays {
    display: flex;
    margin-right: 17px;
    align-items: center;
    gap: 2px;
    
    img {
      height: 10.67px;
      width: 10.67px;
    }
    p {
      margin-top: unset;
      font-size: 10.67px;
    }
    }

    .ap-div-icons .like-share {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-inline: 6px;

    img {
      height: 16.67px;
      width: 16.67px;
    }
    }

    
    .progress-bar {
    display: none;
    margin-top: 6px;
    width: 100%;
    height: 5px;
    padding: 1px;
    border-radius: 6px;
    background-color: var(--clr-txt-500);
    position: relative;
    cursor: pointer;
    }

    .progress {
    display: none;
    height: 100%;
    background-color: var(--clr-60);
    }
}