/* Profile Css */

.profile-div-main-artist {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-color: rgb(var(--clr-background));

  nav {
    display: flex;
    align-items: center;
    min-height: 66.67px;
    padding-inline: clamp(20px, 5.55vw, 50px);
    justify-content: space-between;
    z-index: 10;
  }

  nav p {
    font-family: logo;
    font-size: 21.33px;
    color: rgba(var(--clr-font), 1);
  }
  nav img {
    height: 29.67px;
    width: 29.67px;
  }

  .nav-div-search {
    display: flex;
    align-items: center;
    gap: 6px;
    position: relative;
  }

  .input-search {

    font-family: mainfont;
    height: 28px;
    background-color: rgba(0,0,0, .8);
    border: 1px solid rgba(var(--clr-main), .5);
    border-radius: 3px;
    padding: 3px 6px;
    color: #ffffff7a;
  }

  .input-search:focus {
    outline: none;
    border: 1px solid rgba(var(--clr-main), .5);
  }

  .search-div-result-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 0;
    width: 100%;
    background-color: rgba(var(--clr-font), .4);
    
    position: absolute;
    z-index: 5;
    transition: .6s;
    top: 34px;
    border-radius: 10px;
    opacity: 0;
  }

  .nav-img-search {
    position: relative;
  }

  .slideDown {
    opacity: 1;
    height: 180px;
  }
  
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  

  .wrap-info-about {
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 150px;
    position: relative;
    overflow: hidden;
    opacity: 0;
    transition: opacity .6s ease-in;
    padding-bottom: 20px;
    border-radius: 0 0 20px 20px;
    z-index: 8;
  }

  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
  }

  .background .background-color {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: linear-gradient(to bottom, rgba(var(--clr-background-lighter), 0.2) 0%, rgba(var(--clr-background-lighter), 0.5) 60%, rgba(var(--clr-background-lighter), 1) 100%);
  }
  .background .background-color-two {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: rgba(10, 10, 10, .3);
  }
  .background img {
    position: absolute;
    background-position: top;
    object-position: top;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 0;
    filter: opacity(0.4);
    opacity: 0;
    transition: opacity 6s ease-in;
  }
  
  .wrap-info-stats {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    z-index: 5;
    padding-inline: clamp(20px, 5.55vw, 50px);
  }

  .wrap-info-stats .info {
    display: flex;
    flex-direction: column;

    .location {
      margin-top: 5px;
    }
  }
  
  .wrap-info-stats .stats {
    display: flex;
    align-items: end;
    min-width: 76.8px;
    justify-content: space-between;

    .icon {
      opacity: 1;
    }
  }
  
  .wrap-info-stats .stats div {
    display: flex;
    height: 24px;
    color: rgba(var(--clr-main), 1);
    align-items: center;
  }
  
  .wrap-info-stats .stats div :nth-child(2) {
    margin: 2px 4px 2px 2px;
    font-size: 14px;
  }
  
  .wrap-name-varified {
    display: flex;
    gap: 3px;
    align-items: center;
  }
  
  .wrap-name-varified img {
    margin-top: calc(0.4rem * 1.618 * 1.618 * 1.618);
    opacity: .7;
    margin-bottom: 2.5px;
  }

  .about {
    width: 100%;
    margin-top: 100px;
    display: flex;
    z-index: 5;
    padding-inline: clamp(20px, 5.55vw, 50px);
  }
}