.upi-div-main {
    position: absolute;
    width: 100%;

    .signup-div-inputswrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: rgb(var(--clr-background));
        z-index: 10;
        position: relative;
    }

    .au-div-heading-and-btn .icon {
        align-self: start;
        margin-right: 24px;
        margin-top: 30px;
    }
}