.vd-div-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    color: #000;

    /* below goes css for view being debugged */

    .ap-div-icons {
        position: relative;
        padding: 0 4px 0 8px;
        display: flex;
        align-items: center;
        background-size: cover;
        z-index: 1;
      }
    
      .ap-div-icons .plays {
        display: flex;
        margin-right: 17px;
        align-items: center;
        gap: 2px;
    
        img {
          height: 10.67px;
          width: 10.67px;
        }
        p {
          margin-top: unset;
          font-size: 10.67px;
        }
      }
    
      .background-video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        /* min-height: 100%; */
        width: auto;
        height: auto;
        z-index: -1;
        transform: translate(-50%, -50%);
      }
}