/* Profile Css */
  
  /* root variables for specific component */

.loginsignup-div-main {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-color: rgb(var(--clr-background-lighter));
    transition: opacity .6s ease-in;
   
    nav {
      display: flex;
      align-items: center;
      height: 70px;
      padding-inline: clamp(20px, 5.55vw, 50px);
      justify-content: space-between;
      position: relative;
      z-index: 10;
      transition: height .6s ease-out;
    }

    .nav-div-heading {
      display: flex;
      gap: 3px;
      flex-direction: column;
    }
  
    nav p {
        transition: filter .6s ease-in;
      }

    nav .logo {
      font-family: logo;
      font-size: 21.33px;
    }
    
    .login-background {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      pointer-events: none;
    }
  
    .login-background .background-color {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      background: linear-gradient(to bottom, rgba(var(--clr-background), 0.2) 0%, rgba(var(--clr-background), 0.5) 60%, rgba(var(--clr-background), 1) 100%);
      pointer-events: none;
    }
    .login-background .background-color-two {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      /* background: rgba(10, 10, 10, .3); */
    }
    .login-background img {
      position: absolute;
      background-position: center;
      object-position: center;
      object-fit: cover;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 0;
      filter: opacity(0.4);
      pointer-events: none;
      transition: 9s ease;
    }


    .login-div-main,
    .signup-div-main {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
    }

    .login-div-main {
      border-radius: 0 0 20px 20px;
    }

    .signup-div-main {
      margin-top: 10px;
      border-radius: 20px;
      background-color: rgba(var(--clr-background), 1);
      height: fit-content;
    }

    .signup-div-main .login-quicklinks {
      padding: 24px;

      h5 {

      }

      ul {
        margin-top: 10px;
        list-style-type: none;
        gap: 8px;
      }

      li {
        padding-block: 2px;
      }
    }

    .login-div-inputswrapper,
    .signup-div-inputswrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 8px;
        height: 0;
        justify-content: center;
        overflow: hidden;
        transition: min-height 1.2s cubic-bezier(0.22, 0.61, 0.36, 1), opacity .6s cubic-bezier(0.22, 0.61, 0.36, 1);
        position: relative;
        z-index: 1;
    }

    .login-p-infomsg {
      padding-top: 25px;
      position: relative;
      font-size: 14px;
      text-align: center;
      max-width: 160px;
      transition: .3s ease;
      line-height: 1.2;
    }

    .signup-button,
    .login-button,
    .p-button {
        margin-top: 10px;
        font-family: main-bold;
        border-radius: 26px;
        padding: 8px 18px;
        background-color: #fff;
        font-size: 14.33px;
        color: #000;
        border: none;
    }

    .p-button {
      margin-top: unset;
      opacity: .7;
    }

    .footer-div-main {
        display: none;
    }
}