
  .dialog-div-main dialog {
    display: flex;
    flex-direction: column;
    position: fixed;
    max-width: 250px;
    border: none;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    border: none;
    transition: opacity .6s;
    background-color: rgba(var(--clr-background), .06);
    padding: 20px;
    border-radius: 6px;
    gap: 10px;
    z-index: 8;
  }

  .dialog-div-main dialog h3 {
    margin-top: unset;
    font-size: 17.33px;
  }

  .dialog-div-main dialog h4 {
    font-size: 14px;
    opacity: .6;
  }

  .dialog-div-main dialog .dialog-div-buttons{
    display: flex;
    justify-content: space-between;
  }

  .dialog-div-main dialog input {
    font-size: 17.33px;
    font-family: mainfont;
    color: rgba(var(--clr-main), 1);
    background-color: transparent;
    border: none;
  }