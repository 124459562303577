  
  /* root variables for specific component */

  :root {
    /* note: color format rgba() gets added later when color variable is being used */
   --clr-main: 26, 26, 26;
   --clr-font: 255, 255, 255;
   --clr-icon: 95, 95, 95;
   --clr-background: 14, 14, 14;
   --clr-background-lighter: 26, 26, 26;
 }
 

 @font-face {
  font-family: main-light;
  src: url(../asset/font/SUSE-Regular.ttf)
}

@font-face {
  font-family: mainfont;
  src: url(../asset/font/SUSE-Regular.ttf);
}

@font-face {
  font-family: main-bold;
  src: url(../asset/font/SUSE-Regular.ttf);
}

@font-face {
  font-family: logo;
  src: url(../asset/font/SairaStencilOne-Regular.ttf);
}

* {
  box-sizing: border-box;
}

* {
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%
}

/* Font Settings */

h1 {
  margin-top: calc(0.4rem * 1.618 * 1.618 * 1.618);
  line-height: 0.9;
  font-family: main-bold;
  font-size: 21.33px;
  color: rgba(var(--clr-font), 1);
}

h2 {
  /*margin-bottom: calc(0.4rem * 1.618);*/
  margin-left: -1px;
  line-height: 1;
  font-family: main-light;
  font-size: 16px;
  color: rgba(var(--clr-font), 1);
  /* delete below code after testing*/
}

h3 {
  /* margin-top: calc(0.4rem * 1.618 * 1.618 * 1.618); */
  line-height: 1;
  font-family: main-bold;
  font-size: 17.33px;
  color: rgba(var(--clr-font));
  text-transform: capitalize;
}

h4 {
  line-height: 1;
  font-family: mainfont;
  color: rgba(var(--clr-font), .63);
}

h5 {
  /* margin-top: calc(0.4rem * 1.618 * 1.618 * 1.618); */
  line-height: 1;
  color: rgba(var(--clr-font), .9);
  text-transform: capitalize;
}

p, li {
  line-height: 1;
  font-family: mainfont;
  font-size: 12.67px;
  color: rgb(var(--clr-font), .63);
}
/* move to reset css*/
img {
  width: 100%;
}

a {
  text-decoration: none;
}

.clickable {
  cursor: pointer;
}

.default-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: main-bold;
  border-radius: 26px;
  padding: 6px 16px;
  background-color: rgba(var(--clr-font));
  font-size: 14.33px;
  color: rgb(0, 10, 20);
  opacity: .9;
  border: none;
  transition: .4s ease;
}
.default-button:active {
  transform: scale(.99);
}

.icon {
  width: clamp(calc(24px / 1.618), 4.23vw, 24px);
  height: clamp(calc(24px / 1.618), 4.23vw, 24px);
}
.logo {
  color: rgba(var(--clr-font));
}

.space-between {
  display: flex;
  width: 100%;
  justify-content: space-between;
}


/* Body Settings */
.app {
  width: 100%;
}
body {
    width: 100%;
    height: 100vh;
    background-color: rgba(var(--clr-background-lighter), 1);
    color: rgba(var(--clr-font), .8);
    font-size: 1.6rem;
    font-family: mainfont;
    transition: .2s ease-in;
}

body #root {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  background-color: transparent;
  height: 3px;
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(var(--clr-font), .3);
  border-radius: 20px;
}

.loader {
  width: 100%;
  height: 1.5px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.loader::after {
  content: '';  
  width: 96px;
  height: 1.5px;
  background: rgba(var(--clr-font));
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: hitZak 1.5s ease-in-out infinite alternate;
}

/* amination classes */

.anim-paragraph {
  animation: paragraph .3s ease-in-out;
}

@keyframes hitZak {
  0% {
    left: -50%;
    transform: translateX(-1%);
  }
  100% {
    left: 150%;
    transform: translateX(-99%);
  }
}
    