  
  /* root variables for specific component */

  .uploadpage-div-main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    border-radius: 6px;
    background-color: rgba(var(--clr-background));
    justify-content: space-between;
    padding: 24px;

    .div-header {

      h1 {
        margin-top: unset;
      }

      p {
        margin-top: 4px;
        opacity: .6;
      }
    }



    .div-body {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 12px;
      padding: 24px;
      max-width: 360px;
      overflow-x: hidden;
    }

    form .not-current-wrap {
      position: absolute;
      width: 0;
      opacity: 0;
      transform: translateX(-100vw);
      animation: formElementSlideOut .4s ease-in-out;
      transition: opacity .6s ease-in-out;
    }

    form .current-wrap {
      position: absolute;
      width: 100%;
      opacity: 1;
      transition: opacity .6s ease-in-out;
      animation: formElementSlideIn .4s ease-in-out;
    }

    form .au-form-head {
      padding: 0;
    }

    form h3 {
      width: 100%;
      text-align: left;
    }

    form p {
      width: 100%;
      text-align: left;
      margin-top: 4px;
      opacity: .6;
    }

    form .main-wrap {
      width: 100%;
      position: relative;
      margin-top: 50px;
    }

    form .default-button {
      margin-top: 25%;
      width: 100%;
      min-width: 80px;
      padding: 8px 3px;
      border-radius: 26px;
    }

    form .audio-input {
      border-radius: 6px;
    }

    .formElementSlideIn {
      animation: formElementSlideIn 4s ease-in;
    }

    @keyframes formElementSlideOut {
      from {
        transform: translateX(50%);
        opacity: 0;
      }
      to {
        transform: translateX(0);
        opacity: 1;
      }
    }

    .formElementSlideOut {
      animation: formElementSlideOut .9s ease-in;
    }

    @keyframes formElementSlideIn {
      from {
        transform: translateX(50%);
        opacity: 0;
      }
      to {
        transform: translateX(0);
        opacity: 1;
      }
    }


    footer {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
    }
    .footer-loginsignup {
      opacity: .6;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }

    .footer-loginsignup .row {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .footer-loginsignup .row .default-button {
      min-width: 80px;
      padding: 8px 3px;
      background-color: transparent;
      border-radius: 26px;
      border: 1px solid rgba(var(--clr-font));
      color: rgba(var(--clr-font));
    }
  }